<template>
    <masked-input :id="id"
                  :class="maskedInputClass"
                  :placeholder="placeholder"
                  v-model="phoneNumber"
                  mask="(###) ###-#### x#####"
                  type="tel"
                  @keyup.native="keyup"
                  :disabled="disabled"/>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import {TheMask as MaskedInput} from 'vue-the-mask';
import {Phone} from '@/model/phone';
import {trimToEmpty} from '@/util/formatters';

@Component({
    components: {
        MaskedInput
    }
})
export default class PhoneNumber extends Vue {

    @Prop({type: String, default: null}) id;
    @Prop({type: String}) value;
    @Prop({type: String, default: 'form-control'}) inputClass;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: Boolean, default: false}) showStatus;
    @Prop({type: String, default: 'Phone Number'}) placeholder;

    numberBuffer = '';

    get isValid() {
        return Phone.REGEX.test(this.numberBuffer);
    }

    get phoneNumber() {
        return trimToEmpty(this.value).replace(/[^\d]/g, '');
    }

    set phoneNumber(value) {
        this.numberBuffer = value;
        this.$emit('input', value);
    }

    get maskedInputClass() {

        if (!this.showStatus) {
            return this.inputClass;
        }

        return this.inputClass + ' ' + (this.isValid ? 'is-valid' : 'is-invalid');
    }

    keyup() {
        this.$emit('state', this.isValid);
    }
}
</script>
<style scoped>

</style>