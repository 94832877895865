<template>
    <div class="enroll-ack">
        <b-row class="home-content">
            <b-col>
                <template v-if="submitted">
                    <h1>Application Submitted</h1>
                    <p>
                        Thank you for applying to Superintendent Career Training. Your application has been received and is under review.
                        Once the application review process is complete, an additional email notification will be sent informing you either:<br />
                        You are registered into the program.<br />
                        OR<br />
                        Your application is declined.<br />
                        If you have any questions regarding this notification, please contact <a href="mailto:sct@carpenters.org">sct@carpenters.org</a>.
                    </p>
                </template>
                <template v-else>
                    <h1>Application Received</h1>
                    <p>
                        Thank you for applying to Superintendent Career Training. Your application was previously received and is under review.
                        Once the application review process is complete, an additional email notification will be sent informing you either:<br />
                        You are registered into the program.<br />
                        OR<br />
                        Your application is declined.<br />
                        If you have any questions regarding this notification, please contact <a href="mailto:sct@carpenters.org">sct@carpenters.org</a>.
                    </p>
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator';

    @Component
    export default class EnrollmentAcknowledgement extends Vue {
        @Prop({type: Boolean, default: false}) submitted;
    }
</script>

<style scoped>

</style>