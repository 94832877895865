<template>
    <div class="enroll">
        <b-row>
            <b-col class="banner-without-picture">
                Enrollment
            </b-col>
        </b-row>
        <template v-if="!completed">
            <b-row class="home-content">
                <b-col>
                    <h1>Eligibility Requirements to Apply</h1>
                </b-col>
            </b-row>
            <b-row class="home-content">
                <b-col>
                    <ul>
                        <li>UBC Journeyman member in good standing.</li>
                        <li>Employed and designated by a signatory contractor.</li>
                        <li>Have a mentor from the contractor willing to work with them throughout the 18-month program.</li>
                        <li>OSHA 30 Construction wallet card in TRAIN or Canadian Work Ready for Dispatch.</li>
                        <li>Mentor must hold title higher than Foreman.</li>
                    </ul>
                </b-col>
            </b-row>
            <!--If program 1 sessions are available, show enrollment rules, form, acknowledgement-->
            <template v-if="hasProgram1s">
                <enrollment-form v-model="completed"
                                 @received="receiveApp"
                                 @submitted="submitApp" />
            </template>
            <!--If no program 1 sessions are available, do't show enrollment-->
            <template v-else>
                <b-row class="home-content">
                    <b-col>
<!--                        <h1>CITF is not accepting new applications at this time. Please check back soon.</h1>-->
                        <h1>We are currently not accepting new applications. Please check back soon.</h1>
                    </b-col>
                </b-row>
            </template>
        </template>
        <template v-else>
            <enrollment-acknowledgement :submitted="submitted" />
        </template>
    </div>
</template>
<script>

import { Vue, Component } from 'vue-property-decorator';
import store from '@/store/store';
import EnrollmentForm from './EnrollmentForm.vue';
import EnrollmentAcknowledgement from "./EnrollmentAcknowledgement.vue";
import _ from 'underscore';
import {errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        EnrollmentForm,
        EnrollmentAcknowledgement
    }
})
export default class Enrollment extends Vue {
    //Has application been completed? (display acknowledgement)
    completed = false;
    //Has application been completed as a submission? (i.e. determine whether already received)
    submitted = false;

    //Application has already been recieved, complete with no new submission
    receiveApp() {
        this.completed = true;
        this.submitted = false;
    }

    //Application has been newly submitted, complete
    submitApp() {
        this.completed = true;
        this.submitted = true;
    }

    get hasProgram1s() {
        const program1s = store.getters['tracks/getProgram1s'];
        return !_.isEmpty(program1s);
    }

    async mounted() {
        try {
            await store.dispatch('tracks/loadProgram1s');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        _.each([
            'mentors/loadMemberMentors',
            'organizations/loadContractorTypes',
            'applications/loadApplicationStatuses',
            'common/loadStates'
        ], (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }
}

</script>
<style scoped>
    div.banner-without-picture {
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        font-size: 36px;
        font-weight: bold;
    }
</style>