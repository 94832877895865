<template>
    <div>
        <b-row class="home-content">
            <b-col>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-row>
                                <b-col>
                                    <b-form-group label="Program" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <b-col>
                                                <b-form-group :state="errorState('session')" invalid-feedback="Required" label="Program:" label-for="program">
                                                    <b-select :state="errorState('session')" id="program" size="sm" :options="programs" v-model="application.session" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-row>
                                <b-col>
                                    <b-form-group label="Member Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <b-col cols="4">
                                                <b-form-group :state="errorState('memberUbcId')" invalid-feedback="Required" label="UBC ID:" label-for="ubc-id">
                                                    <b-input-group>
                                                        <b-input :id="'ubc-id'"
                                                                 placeholder="U-0000-0000"
                                                                 class="form-control form-control-sm"
                                                                 v-model="application.member.ubcId"
                                                                 :state="errorState('memberUbcId')"
                                                                 @submitted="lookupMemberUbcId(application.member)"/>
                                                        <!-- Masked input has known issue with field clearing-->
                                                        <!--                                                    <ubc-id :id="'ubc-id'"-->
                                                        <!--                                                            input-class="form-control form-control-sm"-->
                                                        <!--                                                            v-model="application.member.ubcId"-->
                                                        <!--                                                            :state="errorState('memberUbcId')"-->
                                                        <!--                                                            @submitted="lookupMemberUbcId(application.member)"/>-->
                                                        <b-input-group-append>
                                                            <b-button size="sm" variant="primary"
                                                                      @click="lookupMemberUbcId(application.member)">Lookup</b-button>
                                                            <b-button size="sm" variant="warning"
                                                                      v-if="!!application.member.ubcId"
                                                                      @click="resetMember">Clear</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col v-if="showMemberForm">
                                                <b-form-group label="Local Union:" label-for="local-union">
                                                    <b-input id="local-union" size="sm" v-model="application.member.local" disabled/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col v-if="showMemberForm">
                                                <b-form-group label="Classification:" label-for="classification">
                                                    <b-input id="classification" size="sm" v-model="application.member.classification" disabled/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="showMemberForm">
                                            <b-col md>
                                                <b-form-group label="First Name:" label-for="first-name">
                                                    <b-input id="first-name" size="sm" v-model="application.member.firstName" disabled/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md>
                                                <b-form-group label="Middle Name:" label-for="middle-name">
                                                    <b-input id="middle-name" size="sm" v-model="application.member.middleName" disabled/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md>
                                                <b-form-group label="Last Name:" label-for="last-name">
                                                    <b-input id="last-name" size="sm" v-model="application.member.lastName" disabled/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="showMemberForm">
                                            <b-col md lg="4">
                                                <b-form-group label="Suffix:" label-for="suffix">
                                                    <b-input id="suffix" size="sm" v-model="application.member.suffix" disabled/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md lg="4">
                                                <b-form-group :state="errorState('memberTitle')" invalid-feedback="Required" label="Job Title:" label-for="title">
                                                    <b-input :state="errorState('memberTitle')" id="title" size="sm" v-model="application.member.title" :disabled="application.member.disableTitle"/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-form-group label="Contact Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                <b-form-group label="Street Address:" label-for="address1">
                                    <b-input id="address1" size="sm" v-model="application.member.address1" disabled/>
                                </b-form-group>
                                <b-form-group label="" label-for="address2">
                                    <b-input id="address2" size="sm" v-model="application.member.address2" disabled/>
                                </b-form-group>
                                <b-row>
                                    <b-col md lg="8">
                                        <b-form-group label="City:" label-for="city">
                                            <b-input id="city" size="sm" v-model="application.member.city" disabled/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="2">
                                        <b-form-group label="State/Province:" label-for="state">
                                            <state-select :id="'state'" size="sm" v-model="application.member.state" disabled/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="2">
                                        <b-form-group label="ZIP/Postal Code:" label-for="zip">
                                            <b-input id="zip" size="sm" v-model="application.member.zip" maxlength="10" disabled/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('memberEmail')" invalid-feedback="Valid email required" label="Email Address:" label-for="email">
                                            <b-input :state="errorState('memberEmail')" id="email" size="sm" v-model="application.member.email" :disabled="application.member.disableEmail"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group
                                                :state="errorState('memberPhone')"
                                                invalid-feedback="Valid phone number required"
                                                label="Mobile Phone:"
                                                label-for="mobile-phone">
                                            <phone-number id="mobile-phone"
                                                          input-class="form-control form-control-sm"
                                                          v-model="application.member.phone.formatted"
                                                          :disabled="application.member.disablePhone"
                                                          :state="errorState('memberPhone')"
                                                          show-status />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-form-group label="Emergency Contact Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('memberEmergencyContactName')" invalid-feedback="Required" label="Name:" label-for="emergency-contact-name">
                                            <b-input id="emergency-contact-name"
                                                     size="sm"
                                                     v-model="application.member.emergencyContact.name"
                                                     :disabled="application.member.disableEmergencyContact"
                                                     :state="errorState('memberEmergencyContactName')" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('memberEmergencyContactRelationship')" invalid-feedback="Required" label="Relationship:" label-for="emergency-contact-relationship">
                                            <b-input id="emergency-contact-relationship"
                                                     size="sm"
                                                     v-model="application.member.emergencyContact.relationship"
                                                     :disabled="application.member.disableEmergencyContact"
                                                     :state="errorState('memberEmergencyContactRelationship')" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('memberEmergencyContactDayPhone')" invalid-feedback="Valid phone number required" label="Day Phone:" label-for="emergency-contact-day-phone">
                                            <phone-number id="emergency-contact-day-phone"
                                                          input-class="form-control form-control-sm"
                                                          v-model="application.member.emergencyContact.dayPhone.formatted"
                                                          :disabled="application.member.emergencyContact.disableDayPhone"
                                                          :state="errorState('memberEmergencyContactDayPhone')"
                                                          show-status />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('memberEmergencyContactNightPhone')" invalid-feedback="Valid phone number required" label="Night Phone:" label-for="emergency-contact-night-phone">
                                            <phone-number id="emergency-contact-night-phone"
                                                          input-class="form-control form-control-sm"
                                                          v-model="application.member.emergencyContact.nightPhone.formatted"
                                                          :disabled="application.member.emergencyContact.disableNightPhone"
                                                          :state="errorState('memberEmergencyContactNightPhone')"
                                                          show-status />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-form-group label="Mentor Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                <b-row>
                                    <b-col>
                                        <b-form-group :state="errorState('mentorAssignment')" invalid-feedback="Required (Mentor cannot match Member or Contractor contact)" label="Select a Mentor" label-for="existing-mentor">
                                            <b-select :state="errorState('mentorAssignment')" id="existing-mentor" v-model="application.mentor" :options="mentors" size="sm" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="showMentorForm">
                                    <b-col>
                                        <b-card bg-variant="dark" text-variant="white">
                                            <b-row>
                                                <b-col>
                                                    <b-row>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorFirstName')" invalid-feedback="Required"
                                                                          label="First Name:" label-for="mentor-first-name">
                                                                <b-input :state="errorState('mentorFirstName')" id="mentor-first-name" size="sm"
                                                                         v-model="application.mentor.firstName" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group label="Middle Name:" label-for="mentor-middle-name">
                                                                <b-input id="mentor-middle-name" size="sm"
                                                                         v-model="application.mentor.middleName" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorLastName')" invalid-feedback="Required"
                                                                          label="Last Name:" label-for="mentor-last-name">
                                                                <b-input :state="errorState('mentorLastName')" id="mentor-last-name" size="sm"
                                                                         v-model="application.mentor.lastName" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md lg="4">
                                                            <b-form-group label="UBC ID:" label-for="mentor-ubc-id">
                                                                <b-input-group>
                                                                    <!--TODO: add validation if non-null?-->
                                                                    <b-input :id="'mentor-ubc-id'"
                                                                             placeholder="U-0000-0000"
                                                                             class="form-control form-control-sm"
                                                                             v-model="application.mentor.ubcId"
                                                                             @submitted="lookupMentorUbcId(application.mentor)"/>
                                                                    <!-- Masked input has known issue with field clearing-->
                                                                    <!--                                                                <ubc-id :id="'mentor-ubc-id'"-->
                                                                    <!--                                                                        input-class="form-control form-control-sm"-->
                                                                    <!--                                                                        v-model="application.mentor.ubcId"-->
                                                                    <!--                                                                        @submitted="lookupMentorUbcId(application.mentor)"/>-->
                                                                    <b-input-group-append>
                                                                        <b-button size="sm" variant="primary"
                                                                                  @click="lookupMentorUbcId(application.mentor)">Lookup</b-button>
                                                                        <b-button size="sm" variant="warning"
                                                                                  v-if="!!application.mentor.ubcId"
                                                                                  @click="resetMentor">Clear</b-button>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorTitle')" invalid-feedback="Required"
                                                                          label="Title:" label-for="mentor-title">
                                                                <b-input :state="errorState('mentorTitle')" id="mentor-title" size="sm"
                                                                         v-model="application.mentor.title" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col>
                                                            <b-form-group :state="errorState('mentorAddress1')" invalid-feedback="Required"
                                                                          label="Street Address:" label-for="mentor-address1">
                                                                <b-input :state="errorState('mentorAddress1')" id="mentor-address1" size="sm"
                                                                         v-model="application.mentor.address1" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col>
                                                            <b-form-group label="" label-for="mentor-address2">
                                                                <b-input id="mentor-address2" size="sm"
                                                                         v-model="application.mentor.address2" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md lg="8">
                                                            <b-form-group :state="errorState('mentorCity')" invalid-feedback="Required"
                                                                          label="City:" label-for="mentor-city">
                                                                <b-input :state="errorState('mentorCity')" id="mentor-city" size="sm"
                                                                         v-model="application.mentor.city" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="2">
                                                            <b-form-group :state="errorState('mentorState')" invalid-feedback="Required"
                                                                          label="State/Province:" label-for="mentor-state">
                                                                <state-select :state="errorState('mentorState')" :id="'mentor-state'" size="sm"
                                                                              v-model="application.mentor.state" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="2">
                                                            <b-form-group :state="errorState('mentorZip')" invalid-feedback="Required"
                                                                          label="ZIP/Postal Code:" label-for="mentor-zip">
                                                                <b-input :state="errorState('mentorZip')" id="mentor-zip" size="sm" maxlength="10"
                                                                         v-model="application.mentor.zip" :disabled="application.mentor.disableMentorFields"/>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorEmail')" invalid-feedback="Valid email required"
                                                                          label="Email Address:" label-for="mentor-email">
                                                                <b-input :state="errorState('mentorEmail')" id="mentor-email" size="sm"
                                                                         v-model="application.mentor.email"
                                                                         :disabled="application.mentor.disableMentorFields || application.mentor.disableEmail" />
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorPhone')" invalid-feedback="Valid phone number is required"
                                                                          label="Mobile Phone:" label-for="mentor-phone">
                                                                <phone-number id="mentor-phone"
                                                                              :state="errorState('mentorPhone')"
                                                                              input-class="form-control form-control-sm"
                                                                              v-model="application.mentor.phone.formatted"
                                                                              :disabled="application.mentor.disableMentorFields || application.mentor.disablePhone"
                                                                              :show-status="!application.mentor.disableMentorFields && !application.mentor.disablePhone" />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorEmergencyContact')" invalid-feedback="Required"
                                                                          label="Emergency Contact:" label-for="mentor-emergency-contact">
                                                                <b-input :state="errorState('mentorEmergencyContact')"
                                                                         id="mentor-emergency-contact" size="sm"
                                                                         v-model="application.mentor.emergencyContact.name"
                                                                         :disabled="application.mentor.disableMentorFields || application.mentor.disableEmergencyContact"
                                                                         show-status />
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorEmergencyContactRelationship')" invalid-feedback="Required"
                                                                          label="Relationship:" label-for="mentor-emergency-contact-relationship">
                                                                <b-input :state="errorState('mentorEmergencyContactRelationship')"
                                                                         id="mentor-emergency-contact-relationship" size="sm"
                                                                         v-model="application.mentor.emergencyContact.relationship"
                                                                         :disabled="application.mentor.disableMentorFields || application.mentor.disableEmergencyContact" />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorEmergencyContactDayPhone')"
                                                                          invalid-feedback="Valid phone number required"
                                                                          label="Day Phone:" label-for="mentor-emergency-contact-day-phone">
                                                                <phone-number id="mentor-emergency-contact-day-phone"
                                                                              input-class="form-control form-control-sm"
                                                                              v-model="application.mentor.emergencyContact.dayPhone.formatted"
                                                                              :disabled="application.mentor.disableMentorFields || application.mentor.emergencyContact.disableDayPhone"
                                                                              :show-status="!application.mentor.disableMentorFields && !application.mentor.emergencyContact.disableDayPhone"
                                                                              :state="errorState('mentorEmergencyContactDayPhone')" />
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md lg="4">
                                                            <b-form-group :state="errorState('mentorEmergencyContactNightPhone')"
                                                                          invalid-feedback="Valid phone number required"
                                                                          label="Night Phone:" label-for="mentor-emergency-night-phone">
                                                                <phone-number id="mentor-emergency-night-phone"
                                                                              input-class="form-control form-control-sm"
                                                                              v-model="application.mentor.emergencyContact.nightPhone.formatted"
                                                                              :disabled="application.mentor.disableMentorFields || application.mentor.emergencyContact.disableNightPhone"
                                                                              :show-status="!application.mentor.disableMentorFields && !application.mentor.emergencyContact.disableNightPhone"
                                                                              :state="errorState('mentorEmergencyContactNightPhone')" />
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-card bg-variant="light" class="mb-3">
                            <b-form-group label="Contractor Information" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorName')" invalid-feedback="Required" label="Contractor Name:" label-for="contractor-name">
                                            <b-input :state="errorState('contractorName')" id="contractor-name" size="sm" v-model="application.contractor.name"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorType')" invalid-feedback="Required" label="Type:" label-for="contractor-type">
                                            <b-select :state="errorState('contractorType')" id="contractor-type" size="sm" :options="contractorTypes" v-model="application.contractor.type">
                                                <template v-slot:first>
                                                    <option :value="null"> - </option>
                                                </template>
                                            </b-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group :state="errorState('contractorAddress1')" invalid-feedback="Required" label="Street Address:" label-for="contractor-address1">
                                            <b-input :state="errorState('contractorAddress1')" id="contractor-address1" size="sm" v-model="application.contractor.address1"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group label="" label-for="contractor-address2">
                                            <b-input id="contractor-address2" size="sm" v-model="application.contractor.address2"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md lg="8">
                                        <b-form-group :state="errorState('contractorCity')" invalid-feedback="Required" label="City:" label-for="contractor-city">
                                            <b-input :state="errorState('contractorCity')" id="contractor-city" size="sm" v-model="application.contractor.city"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="2">
                                        <b-form-group :state="errorState('contractorState')" invalid-feedback="Required" label="State/Province:" label-for="contractor-state">
                                            <state-select :state="errorState('contractorState')" :id="'contractor-state'" size="sm" v-model="application.contractor.state"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="2">
                                        <b-form-group :state="errorState('contractorZip')" invalid-feedback="Required" label="ZIP/Postal Code:" label-for="contractor-zip">
                                            <b-input :state="errorState('contractorZip')" id="contractor-zip" size="sm" v-model="application.contractor.zip" maxlength="10"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorContactFirstName')" invalid-feedback="Required" label="Contact First Name:" label-for="contractor-first-name">
                                            <b-input :state="errorState('contractorContactFirstName')" id="contractor-first-name" size="sm"
                                                     v-model="contractorContact.firstName"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorContactLastName')" invalid-feedback="Required" label="Contact Last Name:" label-for="contractor-last-name">
                                            <b-input :state="errorState('contractorContactLastName')" id="contractor-last-name" size="sm"
                                                     v-model="contractorContact.lastName"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorContactTitle')" invalid-feedback="Required" label="Job Title:" label-for="contractor-title">
                                            <b-input :state="errorState('contractorContactTitle')" id="contractor-title" size="sm"
                                                     v-model="contractorContact.title"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorContactEmail')" invalid-feedback="Valid email required" label="Contact Email Address:" label-for="contractor-email">
                                            <b-input :state="errorState('contractorContactEmail')" id="contractor-email" size="sm"
                                                     v-model="contractorContact.email"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md lg="4">
                                        <b-form-group :state="errorState('contractorContactPhone')" invalid-feedback="Valid phone number required" label="Contact Phone:" label-for="contractor-phone">
                                            <phone-number id="contractor-phone"
                                                          input-class="form-control form-control-sm"
                                                          :state="errorState('contractorContactPhone')"
                                                          v-model="contractorContact.phone.formatted"
                                                          show-status  />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showMemberForm">
                    <b-col>
                        <b-button variant="success" :disabled="disableSubmit" block @click="submitApplication">Apply to Superintendent Career Training</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- VERIFY MEMBER MODAL -->
        <b-modal :id="verifyMemberModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Member Verification"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <VerifyUbcId :member="application.member"
                         @cancel="cancelMemberVerification"
                         @done="finishMemberVerification"/>
        </b-modal>
    </div>
</template>


<script>
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {UbcId, UBCID_REGEX} from '@/components/shared/UbcId';
import _ from 'underscore';
import store from '@/store/store';
import {NULL_APPLICATION} from '@/model/enrollment';
import PhoneNumber from '@/components/shared/PhoneNumber.vue';
import StateSelect from '@/components/shared/StateSelect.vue';
import VerifyUbcId from './VerifyUbcId';
import memberDao from '@/dao/member_dao';
import applicationDao from "@/dao/application_dao";
import {NULL_MEMBER, NEW_MENTOR, Member} from "@/model/member";
import {NULL_SESSION_P1} from "@/model/session";
import {NULL_CONTACT} from "@/model/organization";
import {errorModalOptions, errorToastOptions} from "@/util/formatters";
import {sprintf} from "sprintf-js";
import {trimToNull} from '@/util/formatters';

@Component({
    components: {
        UbcId,
        PhoneNumber,
        StateSelect,
        VerifyUbcId
    }
})

export default class EnrollmentForm extends Vue {
    @Prop({type: Boolean, default: false}) value;
    application = NULL_APPLICATION.clone();
    memberVerified = false;
    mentorVerified = false;

    get verifyMemberModalId() {
        return sprintf('verify-member-%s', this.application.member.ubcId);
    }

    cancelMemberVerification() {
        this.memberVerified = false;
        this.$bvModal.hide(this.verifyMemberModalId);
        //Reset member, verification failed/canceled
        this.application.member = NULL_MEMBER.clone();
    }

    @Watch('application.member.ubcId')
    memberUbcIdUpdated() {
        //Require verification for any member UBC ID changes
        this.memberVerified = false;
    }

    @Watch('application.mentor.ubcId')
    mentorUbcIdUpdated() {
        //Require verification for any mentor UBC ID changes (if mentor is not selected from list)
        if (this.application.mentor.newEntry) {
            this.mentorVerified = false;
        }
        //Mentor UBC ID is not required
        if (!NULL_MEMBER.equals(this.application.mentor) && _.isEmpty(this.application.mentor.ubcId)) {
            this.mentorVerified = true;
        }
    }

    finishMemberVerification() {
        this.memberVerified = true;
        // console.log(this.memberVerified);
        this.$bvModal.hide(this.verifyMemberModalId);
        this.completeMemberLookup(this.application.member);
    }

    //Clear cache to remove option to bypass UBC ID validation
    beforeMount() {
        this.application = NULL_APPLICATION.clone();
    }

    get sessionOptions() {
        const program1s = store.getters['tracks/getProgram1s'];
        return program1s;
    }

    get disableSubmit() {

        if (!this.memberVerified) {
            return true;
        }

        // If some fields are in error, disable the apply button
        const errors = this.application.applicationErrors;
        return _.some(Object.keys(errors), (f) => errors[f]);
    }

    errorState(field) {
        //applicationErrors[field] returns true when an error is present
        //errorState(field) returns false when an error is present for field control
        return !this.application.applicationErrors[field];
    }

    get contractorContact() {
        const contact = this.application.contractor.contacts[0] || NULL_CONTACT.clone();
        // console.log(contact);
        return contact;
    }

    set contractorContact(contact) {
        this.application.contractor.contacts[0] = contact;
    }

    get showMemberForm() {
        return !!this.application.member.ubcId && !!this.application.member.firstName && this.memberVerified;
    }

    get showMentorForm() {
        return this.application.mentor.isNewMentor;
    }

    get mentors() {
        const mentors = this.$store.getters['mentors/mentorsAsMembers'];
        const mentorList = _.chain(mentors)
            .sortBy((m) => {
                return (m.lastName || '').toUpperCase();
            })
            .map((m) => {
                return {
                    value: m,
                    text: m.mentorLabel
                };
            })
            .value();
        // Option to add new mentor
        mentorList.unshift({
            value: NEW_MENTOR.clone(),
            text: 'Enter a New Mentor'
        });
        // Null default selection
        mentorList.unshift({
            value: NULL_MEMBER.clone(),
            text: ''
        });
        return mentorList;
    }

    get contractorTypes() {
        const contractorTypes = this.$store.getters['organizations/getContractorTypes'];
        const contractorTypesList = _.chain(contractorTypes)
            .map((type) => {
                return {
                    value: type,
                    text: type.type
                };
            }).value();
        return contractorTypesList;
    }

    get programs() {
        let sessions = _.map(this.sessionOptions, (s) => {
            let nullSession = NULL_SESSION_P1.clone();
            nullSession["sessionId"] = s.id;
            return {
                value: nullSession,
                text: s.value
            }
        });
        sessions.unshift({
            value: NULL_SESSION_P1.clone(),
            text: ''
        });
        return sessions
    }

    async lookupMemberUbcId(person) {
        this.application.serialize();
        try {
            if (!UBCID_REGEX.test(person.ubcId)) {
                return await this.$bvModal.msgBoxOk('Please enter a valid UBC ID.', {
                    title: 'Invalid UBC ID',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }
            //Consider implementing a "member exists" type endpoint that only returns UBC ID and flags for
            //verification data on file before verifying member identity
            const member = await memberDao.lookupMember(person.ubcId);
            // console.log(member);
            //Rule: member UBC ID must have Train record association and be eligible for verification
            if (NULL_MEMBER.equals(member) || !member.canBeVerified) {
                return await this.$bvModal.msgBoxOk('We are unable to locate your UBC Journeyman record. ' +
                    'If you feel this is in error, please contact your Local Union to review their records.');
            }
            //Rule: members with valid UBC ID require SSN/SIN/birthdate verification
            //Must assign flags to member for mount
            this.application.member.ssnOnFile = member.ssnOnFile;
            this.application.member.birthdateOnFile = member.birthdateOnFile;
            await this.$bvModal.show(this.verifyMemberModalId);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async completeMemberLookup(person) {
        if (!this.memberVerified) {
            return;
        }
        try {
            //Rule: member should not have an active (enrolled, enabled) trainee profile
            const hasTraineeHistory = await applicationDao.hasTraineeHistory(person.ubcId);
            if (hasTraineeHistory) {
                return await this.$bvModal.msgBoxOk(
                    'Our records show you are currently enrolled in Superintendent Career Training. ' +
                    'If you have any questions regarding this notification, please contact your Mentor.', {
                        title: 'CURRENTLY ENROLLED'
                    });
            }
            //Rule: member cannot have prior applications in applied/enrolled status
            const previousApplications = await applicationDao.getApplicationHistory(person.ubcId);
            const activeApplications = _.filter(previousApplications, a => a.isEnrolled || a.isApplied);
            if (!_.isEmpty(activeApplications)) {
                this.$emit('received');
                return;
            }
            const member = await memberDao.lookupMentor(person.ubcId);
            person.copyFrom(member);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    resetMember() {
        this.application.member = NULL_MEMBER;
        this.memberVerified = false;
        //Vue wants the v-model explicitly reset to update UI
        this.application.member.ubcId = undefined;
    }

    async lookupMentorUbcId(person) {
        this.application.serialize();
        try {
            if (!UBCID_REGEX.test(person.ubcId)) {
                return this.$bvModal.msgBoxOk('Please enter a valid UBC ID.', {
                    title: 'Invalid UBC ID',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }

            const member = await memberDao.lookupMentor(person.ubcId);

            if (member instanceof Member) {
                this.application.mentor.copyFrom(member);
            }
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    resetMentor() {
        this.application.mentor = NEW_MENTOR;
        this.mentorVerified = false;
    }

    validateApplication() {
        const memb = this.application.member;
        //Member should be in good-standing/arrears status with local union
        //If not in good standing, submit anyway but mark declined
        const localStatus = this.$store.getters['applications/getDeclinedLocalStatus'];
        if (_.isEmpty(memb.unionStatus) || !memb.inGoodStanding) {
            this.application.status = localStatus;
            return;
        }
        //Member should have completed required OSHA 30 Construction class
        //Member should have COVID-19 vaccine verification
        //If pre-requisites are not found via system lookup, submit anyway but mark incomplete (0 can be falsy here, not a valid ID)
        if (_.isEmpty(memb.oshaClassScheduleId) || !memb.vaccineVerified) {
            const incompleteStatus = this.$store.getters['applications/getAppliedIncompleteStatus'];
            this.application.status = incompleteStatus;
            return;
        }
        //No issues found, set status as complete
        const validatedStatus = this.$store.getters['applications/getAppliedCompleteStatus'];
        this.application.status = validatedStatus;
        return;
    }

    async submitApplication() {

        if (!this.memberVerified) {
            return this.$bvModal.msgBoxOk(
                `Please ensure the member's UBC ID verification is complete before submitting application.`,
                errorModalOptions);
        }

        try {
            this.validateApplication();
            await this.$store.dispatch('applications/submitApplication', this.application, {root: true});
            this.$emit('submitted');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }
}
</script>


<style scoped>
    .error {
        color: red;
    }
</style>