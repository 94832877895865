<template>
    <div>
        <b-row>
            <b-col>
                <p>To verify your identity, please enter the following information:</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="verifySsn">
                <b-form-group :state="ssnError"
                              invalid-feedback="Please enter the last 4 digits of your SSN/SIN"
                              label="Last 4 digits of your SSN/SIN:"
                              label-for="ssn-entry">
                    <b-input :state="ssnError"
                             v-model="verify"
                             id="ssn-entry"
                             size="sm"/>
                </b-form-group>
            </b-col>
            <b-col v-else>
                <b-form-group :state="birthdateError"
                              invalid-feedback="Please enter your birth date"
                              label="Birth date:"
                              label-for="birthdate-entry">
                    <PickerOfDates :state="birthdateError"
                                   v-model="verify"
                                   id="birthdate-entry"
                                   format="M/d/yyyy"
                                   size="sm"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-button-group>
                    <b-button @click="doClear"
                              variant="primary">Clear</b-button>
                    <b-button @click="doCancel"
                              variant="warning">Cancel</b-button>
                    <b-button @click="doVerify"
                              :disabled="readyToValidate"
                              variant="success">Verify</b-button>
                </b-button-group>
            </b-col>
        </b-row>
    </div>
</template>


<script>
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import PickerOfDates from '@/components/shared/PickerOfDates';
    import {Member, NULL_MEMBER} from "@/model/member";
    import memberDao from "@/dao/member_dao";
    import {date, errorModalOptions} from "@/util/formatters";
    import {isValid} from 'date-fns';
    import _ from 'underscore';

    @Component({
        components: {
            PickerOfDates
        }
    })

    export default class VerifyUbcId extends Vue {
        @Prop({type: Member, default: NULL_MEMBER}) member;
        verify = null;

        mounted() {
            //Preconditions: UBC ID has been verified and valid results have been found
            //(i.e. member has SSN and/or birthdate on file for verification)
            // console.log(this.member);
        }

        get verifySsn() {
            //Prefer SSN over birthdate (at least one should be present, see preconditions)
            return this.member.ssnOnFile;
        }

        get ssnError() {
            if (!this.verifySsn) {
                //No error if not verifying SSN
                return false;
            }
            else {
                //If SSN is required, entry must be exactly 4 digits
                return !_.isEmpty(this.verify) && /^[0-9]{4}$/.test(String(this.verify));
            }
        }

        get birthdateError() {
            if (this.verifySsn) {
                //No error if not verifying birthdate
                return false;
            }
            else {
                //If birthdate is required, entry must be valid date
                return !_.isNull(this.verify) && !_.isUndefined(this.verify) &&
                    _.isDate(this.verify) && isValid(this.verify);
            }
        }

        get readyToValidate() {
            return !this.ssnError && !this.birthdateError;
        }

        doClear() {
            this.verify = null;
        }

        doCancel() {
            this.$emit('cancel');
        }

        async doVerify() {
            const verify = this.verifySsn ? this.verify.toString() : date(this.verify, date.DEFAULT_DATE_FORMAT);
            const verificationRequest = {
                member: JSON.stringify(this.member),
                verify : verify
            };
            // console.log(verificationRequest);
            try {
                const verified = await memberDao.verifyMember(verificationRequest);
                if (verified) {
                    this.$emit('done');
                }
                else {
                    await this.$bvModal.msgBoxOk(
                        'The information you entered does not match our records. ' +
                        'If you feel this is in error, please contact your Local Union to review their records.');
                    this.doCancel();
                }
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
    }
</script>

<style scoped>
</style>